import React from "react";
import { FooterWrapper, Logo, ImagesWrapper, ParpImagesWrapper } from "./style";

import logo from "../../assets/connectly_logo.png";

import StripeLogo from "../../assets/stripe.png";
import AWSLogo from "../../assets/aws.png";
import SendgridLogo from "../../assets/sendgrid.png";

import FELogo from "../../assets/feLogo.jpg";
import RPLogo from "../../assets/rpLogo.jpeg";
import PARPLogo from "../../assets/parpLogo.jpeg";
import UELogo from "../../assets/ueLogo.jpeg";
import { integrationsData } from "../../data/data";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <Logo src={logo} alt="Logo" />

      <ParpImagesWrapper>
        <div>
          <Link to={"https://connectly.travel/dofinansowanie/"}>
            <img src={FELogo} alt="Fundusze europejskie" loading="lazy" />
            <img src={RPLogo} alt="Fundusze europejskie" loading="lazy" />
          </Link>
        </div>

        <div>
          <Link to="https://connectly.travel/dofinansowanie/">
            <img src={PARPLogo} alt="Fundusze europejskie" loading="lazy" />
            <img src={UELogo} alt="Fundusze europejskie" loading="lazy" />
          </Link>
        </div>
      </ParpImagesWrapper>

      <ImagesWrapper>
        <img src={StripeLogo} alt="Stripe logo" loading="lazy" />
        <img src={AWSLogo} alt="AWS logo" loading="lazy" />
        <img src={SendgridLogo} alt="Sendgrid logo" loading="lazy" />
      </ImagesWrapper>

      <ImagesWrapper>
        {integrationsData.map((el, index) => (
          <img key={`${el.id}_${index}`} src={el.imgSrc} alt={`${el.id} logo`} loading="lazy" />
        ))}
      </ImagesWrapper>
    </FooterWrapper>
  );
};

export default Footer;
