import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { columns, IntegrationItemProps } from "../../../data/data";
import ApiService from "../../../api/ApiService";
import IntegrationTableRow from "../row/IntegrationTableRow";
import Pagination from "../../pagination/Pagination";
import { Spinner } from "../../../pages/integration_form_page/tour_cms/step_2/style";
import TripsomniaApiService from "../../../api/TripsomniaApiService";

/*
 * Integration Table List (contains integrations in Connectly)
 */
const IntegrationTableList: React.FC = () => {
  const [accessedCompanies, setAccessedCompanies] = useState<number[]>([]);
  const [integrations, setIntegrations] = useState<IntegrationItemProps[]>([]);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [productSize, setProductSize] = useState<number>();

  const getIntegrations = async () => {
    const apiService = new ApiService();
    apiService
      .getIntegrations(page, size)
      .then((response: any) => {
        setIntegrations(response.data.list);
        setProductSize(Number(response.data.rowCount));
      })
      .catch((err: any) => {});
  };

  const getAccessedCompanies = () => {
    const config = {
      token: sessionStorage.getItem("token") || "",
      email: sessionStorage.getItem("email") || "",
      apiVersion: 6,
    };
    const tripsomniaApiService = new TripsomniaApiService();
    tripsomniaApiService
      .getUserDetails(config)
      .then((response) => {
        console.log("getAccessedTruth");
        const userAccessedCompanyIds = response.data.data.relationships.companies.data.map(
          (item: { uuid: number }) => item.uuid
        );
        console.log("userAccessedCompanyIds: " + JSON.stringify(userAccessedCompanyIds));
        setAccessedCompanies(userAccessedCompanyIds);
      })
      .catch((err) => {
        console.log("getAccessedErr: " + JSON.stringify(err));
      });
  };

  useEffect(() => {
    getAccessedCompanies();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        getIntegrations();
      } catch (error) {
        console.error(error);
      }
    };

    getData();

    const interval = setInterval(() => {
      getData();
    }, 10000);
    return () => clearInterval(interval);
  }, [page, size]);

  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const handlePageSizeChange = (size: number) => {
    setSize(size);
    setPage(1);
  };

  const accessedIntegrations = useMemo(() => {
    return (
      integrations?.filter((integration: IntegrationItemProps) => accessedCompanies.includes(integration.companyId)) ||
      null
    );
  }, [integrations, accessedCompanies]);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <IntegrationHeadCell>#</IntegrationHeadCell>
            {columns?.map((column) => (
              <IntegrationHeadCell key={column}>{column}</IntegrationHeadCell>
            ))}
          </tr>
        </thead>
        <tbody style={{ height: "50px" }}>
          {accessedIntegrations ? (
            accessedIntegrations?.map((integration, index: number) => {
              return (
                <IntegrationTableRow
                  key={`${integration.companyId}-${integration.connectlySources.id}`}
                  index={index + 1}
                  {...integration}
                />
              );
            })
          ) : integrations?.length === 0 ? (
            <div style={{ position: "absolute", right: "40%", fontSize: 20, fontWeight: 700 }}>
              Brak obecnych integracji...
            </div>
          ) : (
            <div style={{ position: "absolute", right: "50%" }}>
              <Spinner size="36px" color="#72bf6a" />
            </div>
          )}
        </tbody>
      </Table>
      <PaginationPanel>
        <Pagination
          currentPage={page}
          totalPages={!isNaN(Number(productSize)) ? Math.ceil(Number(productSize) / size) : 1}
          onPageChange={handlePageChange}
          containerStyle={{ marginTop: "5px" }}
          buttonStyle={{ border: "1px solid gray" }}
          activeButtonStyle={{ backgroundColor: "#72bf6a" }}
          getRowsPerPageCallback={handlePageSizeChange}
          rowsPerPageOptions={[1, 2, 5, 10, 20, 50]}
          defaultRowsPerPage={10}
        />
      </PaginationPanel>
    </>
  );
};

const Table = styled.table`
  bottom: 150px;
  align-items: center;
  margin: auto;
`;

const PaginationPanel = styled.div`
  position: sticky;
  background: white;
  bottom: 0;
  width: 100%;
  z-index: 900;
  margin: 0 !important;
`;

const IntegrationHeadCell = styled.th`
  padding: 10px;
`;

export default IntegrationTableList;
