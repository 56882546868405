import styled from "styled-components";

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999;
  padding: 5px;
  border-top: 1px solid #e0e0e0;
  transition: all 1s ease-in-out;
`;

export const Logo = styled.img`
  width: 173px;
`;

export const ParpImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: fit-content;
  margin: auto;
  margin-top: 10px;

  div,
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-flow: wrap row;
  }
  img {
    height: 50px;
  }
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const ImagesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap row;
  gap: 20px;
  padding: 10px;
  margin-top: 20px;

  img {
    max-width: 80px;
    max-height: 40px;
  }
`;
