import styled from "styled-components";

export const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  top: 0;
  box-sizing: border-box;
  z-index: 999;
  justify-content: space-between;
  padding: 8px 8px 10px 8px;
`;

export const Logo = styled.img`
  width: 130px;

  @media (min-width: 768px) {
    width: 173px;
  }
`;

export const HeaderButtonsAndLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 70px;
  }

  @media (max-width: 350px) {
    gap: 5px;
    flex-flow: column-reverse;
  }
`;

export const HeaderButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
