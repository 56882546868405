import { HeaderButtonProps } from "../components/header/Header";

interface ProductProps {
  id: number;
  offerImg: string;
  title: string;
  description: string;
  supplier: string;
  offerId: string;
  price: number;
}

interface IntegrationsDataProps {
  id: string;
  imgSrc: string;
  active: boolean;
  visible?: boolean;
}

export const integrationsData: IntegrationsDataProps[] = [
  {
    id: "tourCMS",
    imgSrc: "https://cdn.tourdesk.io/blog/post/0f0b8144-f963-40b4-b1b1-db500f333ce6.png",
    active: false,
    visible: true,
  },
  {
    id: "bokun",
    imgSrc: "https://irp-cdn.multiscreensite.com/a3bf8d02/dms3rep/multi/Group+157.svg",
    active: false,
    visible: true,
  },
  {
    id: "fareHarbor",
    imgSrc: "https://seeklogo.com/images/F/fareharbor-logo-1A7BAC1D0C-seeklogo.com.png",
    active: false,
    visible: true,
  },
  {
    id: "yieldPlanet",
    imgSrc: "https://www.logosvgpng.com/wp-content/uploads/2020/11/yieldplanet-logo-vector.png",
    active: false,
    visible: true,
  },
  {
    id: "hotres",
    imgSrc: "https://hotres.pl/public/images/logo.svg",
    active: false,
    visible: true,
  },
  {
    id: "profit_room",
    imgSrc: "https://magazynhotel.pl/wp-content/uploads/2019/09/profitroom-logo-400x160.png",
    active: false,
    visible: false,
  },
];

export interface ConnectlySourceProps {
  id: number;
  name: string;
  status: boolean;
}

export interface IntegrationItemProps {
  companyId: number;
  companyName: string;
  connectlySources: ConnectlySourceProps;
  isActive: boolean;
  destinationSystem: string;
  accountId: number;
  channelId: number;
  offers: number;
  index?: number;
  companySourceId: number;
  commission?: number;
}

export const columns: string[] = ["Company ID", "Opis integracji", "Połączenie", "Konto", "Aktywna", "Ilość ofert"];

export const headerButtons: HeaderButtonProps[] = [
  {
    label: "Strona główna",
    endpoint: "/",
  },
  {
    label: "Lista integracji",
    endpoint: "/integrations_list",
  },
  {
    label: "Formularz integracji",
    endpoint: "/integrationForm/chooseIntegrationElements",
  },
];
