import React, { useState } from "react";
import styled, { css } from "styled-components";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  rowsPerPageOptions: number[];
  defaultRowsPerPage: number;
  containerStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  activeButtonStyle?: React.CSSProperties;
  getRowsPerPageCallback?: (rowsPerPage: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  rowsPerPageOptions,
  defaultRowsPerPage,
  containerStyle,
  buttonStyle,
  activeButtonStyle,
  onPageChange,
  getRowsPerPageCallback,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const pageNumbers = Array.from(Array(totalPages).keys()).map((_, index) => index + 1);

  const handlePageClick = (pageNumber: number) => {
    if (pageNumber !== currentPage) {
      onPageChange(pageNumber);
    }
  };

  const handleRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value));
    getRowsPerPageCallback && getRowsPerPageCallback(parseInt(event.target.value));
  };

  return (
    <PaginationContainer containerStyle={containerStyle}>
      <RowsPerPageContainer>
        <RowsPerPageLabel>size: </RowsPerPageLabel>
        <RowsPerPageSelect value={rowsPerPage} onChange={handleRowsPerPage}>
          {rowsPerPageOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </RowsPerPageSelect>
      </RowsPerPageContainer>
      <div>
        {pageNumbers.map((pageNumber) => (
          <PageButton
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            isActive={pageNumber === currentPage}
            buttonStyle={buttonStyle}
            activeButtonStyle={activeButtonStyle}
          >
            {pageNumber}
          </PageButton>
        ))}
      </div>
    </PaginationContainer>
  );
};

/*
 * Pagination for IntegrationProductList
 */
const PaginationContainer = styled.div`
  display: flex;
  z-index: 1000;
  justify-content: center;
  ${(props: PaginationProps) =>
    props.containerStyle &&
    css`
      ${props.containerStyle}
    `}
`;

const PageButton = styled.button`
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  margin: 4px;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  border-radius: 5px;
  &:hover {
    background-color: #72bf6a;
    color: #fff;
  }

  ${(props: PaginationProps) =>
    props.buttonStyle &&
    css`
      ${props.buttonStyle}
    `}

  ${(props: PaginationProps & { isActive: boolean }) =>
    props.isActive &&
    css`
      background-color: #000;
      color: #fff;

      ${(props: PaginationProps) =>
        props.activeButtonStyle &&
        css`
          ${props.activeButtonStyle}
        `}
    `}
`;

const RowsPerPageContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: inline-block;
  margin: 5px 10px;
`;

const RowsPerPageLabel = styled.label`
  margin-right: 3px;
  font-size: 14px;
`;

const RowsPerPageSelect = styled.select`
  padding: 3px 5px;
  border-radius: 5px;
`;

export default Pagination;
