import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import IntegrationProductTile, {
  ProductProps,
} from "../../../components/integration_product_tile/IntegrationProductTile";
import ApiService from "../../../api/ApiService";
import { toast } from "react-toastify";
import { toastifyParameters } from "../../style/main";
import Button from "../../../components/buttons/Button";
import { ProcessingSpinner, Spinner } from "../../integration_form_page/tour_cms/step_2/style";
import Pagination from "../../../components/pagination/Pagination";
import {
  ButtonGroup,
  FeeForm,
  InputFee,
  IntegrationProducts,
  IntegrationProductsPanel,
  LabelBold,
  PaginationPanel,
  Value,
  View,
} from "../../integration_form_page/style/style";
import { IntegrationTile } from "../../../components/integration_image_tile/style";

const IntegrationProductsYieldPlanetPage = () => {
  const navigate = useNavigate();
  const { companyId, companySourceId, connectlySourceId } = useParams();
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [products, setProducts] = useState<ProductProps[]>();
  const [productSize, setProductSize] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const integrationProductsRef = useRef<HTMLDivElement>(null);
  const [changeFeeStatus, setChangeFeeStatus] = useState<boolean>(false);

  const getProducts = () => {
    if (products && products.length === 0) setLoading(true);
    let data = {
      companyId: Number(companyId),
      sourceId: 1,
      channelId: null,
      page: page,
      size: size,
    };
    const apiService = new ApiService();
    apiService
      .getProducts(data)
      .then((response) => {
        if (products && products.length === 0) {
          toast.success(`Pomyślnie pobrano produkty ! (Strona: ${page} ; Ilość na stronie: ${size})`);
        }
        setProducts(response.data.list);
        setProductSize(Number(response.data.rowCount));
      })
      .catch((err) => {
        toast.error(`Pobranie produktów nie powiodło się !`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (integrationProductsRef.current) {
      integrationProductsRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [page]);

  useEffect(() => {
    if (companyId !== null) getProducts();
  }, [page, size, companyId]);

  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const handlePageSizeChange = (size: number) => {
    setSize(size);
    setPage(1);
  };

  const updateProducts = () => {
    getProducts();
  };

  return (
    <View isProcessing={isProcessing}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px",
          fontSize: "15px",
          fontWeight: "bold",
          color: "#707070",
        }}
      >
        <div>Company ID: {companyId}</div>
        <div>Company Source ID: {companySourceId}</div>
        <div>Connectly Source ID: {connectlySourceId}</div>
      </div>
      <ButtonGroup>
        <Button
          onClick={() => {
            navigate("/integrations_list");
          }}
        >
          WRÓĆ DO LISTY
        </Button>
        <IntegrationTile
          src={"https://www.logosvgpng.com/wp-content/uploads/2020/11/yieldplanet-logo-vector.png"}
          width={50}
          height={30}
          active={true}
          visible={true}
        />
      </ButtonGroup>
      <IntegrationProductsPanel></IntegrationProductsPanel>
      <IntegrationProducts ref={integrationProductsRef}>
        {!loading ? (
          products?.map((product) => {
            return (
              <IntegrationProductTile
                companyId={Number(companyId)}
                key={product.idInTripsomnia}
                actualized={false}
                {...product}
                updateProducts={updateProducts}
                noConnIntegration={true}
              />
            );
          })
        ) : (
          <div
            style={{
              fontSize: "20px",
              color: "#72bf6a",
              display: "flex",
              margin: "5vh auto",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "700",
            }}
          >
            Ładowanie produktów... <Spinner size="20px" color="#72bf6a" />
          </div>
        )}
        {isProcessing && <ProcessingSpinner isProcessing={isProcessing} />}
      </IntegrationProducts>
      <PaginationPanel>
        <Pagination
          currentPage={page}
          totalPages={!isNaN(Number(productSize)) ? Math.ceil(Number(productSize) / size) : 1}
          onPageChange={handlePageChange}
          buttonStyle={{ border: "1px solid gray" }}
          activeButtonStyle={{ backgroundColor: "#72bf6a" }}
          getRowsPerPageCallback={handlePageSizeChange}
          rowsPerPageOptions={[1, 2, 5, 10, 20, 50]}
          defaultRowsPerPage={10}
        />
      </PaginationPanel>
    </View>
  );
};

export default IntegrationProductsYieldPlanetPage;
