import styled from "styled-components";

export const ProductIntegrationDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 55%;
  height: 206px;
  padding: 5px;
  margin: 5px 1px;
  border-radius: 0 8px 8px 0;
  background: #f0f0f0;

  @media (max-width: 768px) {
    height: 146px;
    width: 50%;
  }
`;

export const ProductInfoSummary = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionProductSummary = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelBold = styled.span`
  margin: 3px 5px;
  text-align: center;
  font-size: 13px;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

export const Value = styled.span`
  font-size: 13px;
  text-align: center;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 11px;
  }
`;
