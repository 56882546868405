import React from "react";
import { ButtonWrapper, IconWrapper } from "./style";

interface ButtonProps {
  disabled?: boolean;
  onClick?: (data?: any) => void;
  icon?: React.ReactNode;
  type?: string;
  children: React.ReactNode;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({ onClick, icon, type, disabled, children, className }) => {
  return (
    <ButtonWrapper disabled={disabled} onClick={onClick} type={type} className={className}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {children}
    </ButtonWrapper>
  );
};

export default Button;
