import styled from "styled-components";

export const IntegrationTile = styled.img`
  max-width: ${(props: any) => (props.width ? `${props.width}` : "130px")};
  max-height: ${(props: any) => (props.height ? `${props.height}` : "85px")};
  padding: 5px;
  cursor: pointer;
  margin: 2px;
  border-radius: 3px;
  border: ${(props: any) => (props.active ? "2px solid green" : "1px solid #505050")};
  opacity: ${(props: any) => (props.visible ? 1 : 0.3)};
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: ${(props: any) => (props.visible ? 0.9 : 0.3)};
    transform: ${(props: any) => (props.visible ? "scale(1.05)" : null)};
    border: 1px solid green;
  }

  @media (max-width: 600px) {
    max-width: 85px;
    max-height: 52px;
  }
`;
