import axios, { AxiosInstance } from "axios";
import { LOGIN_URL } from "../config/services";

export var tripsomniaUrlPrefix = "https://tripsomnia.com/api";

if (process.env.REACT_APP_ENV === "production") {
  tripsomniaUrlPrefix = "https://old.tripsomnia.com/api";
}
if (process.env.REACT_APP_ENV === "staging") {
  tripsomniaUrlPrefix = "https://old-stg.tripsomnia.com/api";
}

/*
 * API interfaces data props
 */

interface GetUserDetails {
  token: string;
  email: string;
  apiVersion: number;
}

interface LoginUserProps {
  email: string;
  password: string;
}

/*
 * Tripsomnia API Service (store all endpoints)
 */
class TripsomniaApiService {
  /* POST */
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create();
  }

  getUserDetails = async (data: GetUserDetails) => {
    return await this.axiosInstance.get(`${tripsomniaUrlPrefix}/partner/account`, {
      headers: {
        "X-Admin-Email": data.email,
        "X-Admin-Token": data.token,
        "X-Admin-Api": data.apiVersion,
      },
    });
  };

  loginUser = async ({ email, password }: LoginUserProps) => {
    return await this.axiosInstance.post(`${tripsomniaUrlPrefix}${LOGIN_URL}`, { admin_user: { email, password } });
  };
}

export default TripsomniaApiService;
