import React from "react";
import Dropdown from "../buttons/DropdownButton";
import { HeaderButtonsAndLogo, HeaderButtonsWrapper, HeaderWrapper, Logo } from "./style";

import UEImage from "../../assets/UE.png";

export interface HeaderButtonProps {
  label: string;
  endpoint: string;
}

interface HeaderProps {
  logo: string;
  buttons: HeaderButtonProps[];
  avatar: string;
}

const Header: React.FC<HeaderProps> = ({ logo, buttons }) => {
  return (
    <HeaderWrapper>
      <Logo src={logo} alt="Logo" />

      <HeaderButtonsAndLogo>
        <HeaderButtonsWrapper>
          <Dropdown buttons={buttons} />
        </HeaderButtonsWrapper>

        <a href="https://connectly.travel/dofinansowanie/">
          <img src={UEImage} alt="Unia Europejska" />
        </a>
      </HeaderButtonsAndLogo>
    </HeaderWrapper>
  );
};

export default Header;
