import styled from "styled-components";

export const InfoSummary = styled.div`
  width: 56%;
  margin: 5px 1px;
  border-radius: 0 8px 8px 0;
  background: #f0f0f0;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 600px) {
    height: 140px;
  }
`;

export const Label = styled.span`
  margin: 4px 5px;
  text-align: center;
  padding: 1px;
  font-size: 14px;
`;

export const LabelBold = styled.span`
  margin: 2px 5px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
`;

export const ProductIntegrationDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  width: 90%;
  margin: 5px 1px;
  border-radius: 0 8px 8px 0;
  background: #f0f0f0;
  font-size: 12px;

  @media (max-width: 600px) {
    height: 140px;
  }
`;

export const ProductElementAction = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const ProductStatusSummary = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  align-items: center;
`;

export const ProductElementInfo = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;
