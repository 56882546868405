import React, { useState } from "react";
import styled from "styled-components";
import { StyledToggleButton, StyledToggleKnob } from "./style";

interface ToggleButtonProps {
  defaultChecked: boolean;
  onToggle: (isChecked: boolean) => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ defaultChecked, onToggle }) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const handleToggle = () => {
    const newCheckedValue = !isChecked;
    setIsChecked(newCheckedValue);
    onToggle(newCheckedValue);
  };

  return (
    <StyledToggleButton isChecked={isChecked} onClick={handleToggle}>
      <StyledToggleKnob isChecked={isChecked} />
    </StyledToggleButton>
  );
};

export default ToggleButton;
