import React, { useContext, useEffect, useState } from "react";
import Button from "../../../../components/buttons/Button";
import { getQueryParamValue, redirectToPrevNextStepOfForm } from "../../../../utils/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ButtonGroup,
  Checkbox,
  CheckboxWithLabel,
  Form,
  FormGroup,
  Input,
  InputNumber,
  Label,
} from "../../tour_cms/step_2/style";
import CustomSelect, { Option } from "../../../../components/select/CustomSelect";
import ApiService, { SourcesAssignProps } from "../../../../api/ApiService";
import { MultiStepFormContext } from "../../../../context/MuliStepFormContext";
import { toast } from "react-toastify";
import { toastifyParameters } from "../../../style/main";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

interface FormData {
  companyId: string | null;
  login: string | null;
  password: string | null;
  policy: boolean | null;
}

const initialFormData = {
  companyId: "",
  login: "",
  password: "",
  policy: false,
};

const IntegrationDetailsHotresStep = (props: any) => {
  let location = useLocation();
  let navigate = useNavigate();
  const queryParams = location.search;
  const { handleChangeFormStep } = props;
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const { contextData, setContextData } = useContext(MultiStepFormContext);
  const [isVerificationLoading, setIsVerificationLoading] = useState(false);
  const [companies, setCompanies] = useState<Option[]>();
  const [isSuccessAssign, setSuccessAssign] = useState<Boolean>(false);
  const [formData, setFormData] = useState<FormData>(() => {
    if (queryParams) {
      return {
        accountName: getQueryParamValue(queryParams, "accountName"),
        policy: getQueryParamValue(queryParams, "policy") === "true",
        companyId: getQueryParamValue(queryParams, "companyId"),
        login: getQueryParamValue(queryParams, "login"),
        password: getQueryParamValue(queryParams, "password"),
      };
    } else return initialFormData;
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | any>) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getCompanies = () => {
    const apiService = new ApiService();
    apiService
      .getCompaniesList()
      .then((response) => {
        const optionsData: Option[] = response.data.map((option: any) => ({
          value: option.id,
          label: option.name,
          active: option.active,
        }));
        setCompanies(optionsData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const assignSource = () => {
    let assignSourceData: SourcesAssignProps = {
      sourceId: 4,
      companyId: Number(formData.companyId),
      loginApi: formData.login,
      passwordApi: formData.password,
    };
    const apiService = new ApiService();
    apiService
      .sourcesAssign(assignSourceData)
      .then((response) => {
        toast.success(`Udało się wykonać integracje !`, toastifyParameters);
        setContextData({ ...contextData, fill_integration_details: formData });
        setSuccessAssign(true);
        // setTimeout(() => {
        //   redirectToPrevNextStepOfForm(
        //     location.search,
        //     formData,
        //     "/integrationForm/hotres/integrationProducts?",
        //     handleChangeFormStep
        //   );
        // }, 1500);
      })
      .catch((err) => {
        toast.error(`Nie udało się wykonać integracji`, toastifyParameters);
      });
  };

  const handleBack = () => {
    setContextData({ ...contextData, fill_integration_details: formData });
    redirectToPrevNextStepOfForm(
      location.search,
      formData,
      "/integrationForm/chooseIntegrationElements?",
      handleChangeFormStep
    );
  };

  const handleGenerateAccount = () => {
    const apiService = new ApiService();
    apiService
      .generateTemporaryAccount(Number(formData.companyId))
      .then((response) => {
        setFormData({
          ...formData,
          login: response.data.login,
          password: response.data.password,
        });
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const handleSelectChange = (selected: { active: string; label: string; value: string }) => {
    if (selected) {
      setFormData({
        ...formData,
        companyId: selected.value,
      });
    }
  };

  const disableButton = formData.policy ? true : false;

  return (
    <Form>
      <FormGroup>
        <Label htmlFor="company-id">Company Id</Label>
        <CustomSelect
          data={companies}
          selectedValue={formData.companyId}
          onChange={(selected) => handleSelectChange(selected)}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="accountName-id">Login</Label>
        <Input type="text" id="login" name="login" value={formData.login} onChange={handleChange} />
      </FormGroup>
      <FormGroup style={{ position: "relative" }}>
        <Label htmlFor="accountName-id">Password</Label>
        <Input
          type={showPassword ? "text" : "password"}
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
        />
        {showPassword ? (
          <AiFillEyeInvisible
            style={{ position: "absolute", right: 10, top: 28, color: "#555" }}
            size="24"
            onClick={() => setShowPassword(!showPassword)}
          />
        ) : (
          <AiFillEye
            style={{ position: "absolute", right: 10, top: 28, color: "#555" }}
            size="24"
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
      </FormGroup>
      <FormGroup>
        <CheckboxWithLabel>
          <Checkbox
            id="policy"
            name="policy"
            value={formData.policy}
            checked={Boolean(formData.policy)}
            onChange={handleChange}
          />
          <Label htmlFor="policy">I Agree with Connectly Policy?</Label>
        </CheckboxWithLabel>
      </FormGroup>
      <ButtonGroup>
        <Button disabled={Number(formData.companyId) === 0} onClick={handleGenerateAccount}>
          Generowanie passów
        </Button>
        <Button disabled={isVerificationLoading} onClick={handleBack}>
          Wstecz
        </Button>
        <Button
          disabled={!disableButton}
          onClick={isSuccessAssign ? () => navigate("/integrations_list") : assignSource}
        >
          {isSuccessAssign ? "Wróć do listy" : "Przypisz"}
        </Button>
      </ButtonGroup>
    </Form>
  );
};

export default IntegrationDetailsHotresStep;
