import styled from "styled-components";
import { IntegrationTile } from "./style";

/*
 * Integration image tile in choose integrations connection
 */
const IntegrationImageTile = (props: any) => {
  const { data, onChoose, choosenId } = props;
  const { id, imgSrc, active, visible } = data;

  const handleIntegrationClick = () => {
    if (active) onChoose("");
    else onChoose(id);
  };
  return (
    <div key={id} onClick={handleIntegrationClick}>
      <IntegrationTile src={imgSrc} width={130} height={75} active={active} visible={visible} />
    </div>
  );
};

export default IntegrationImageTile;
