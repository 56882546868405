import React from "react";
import { FaCheck, FaSmile } from "react-icons/fa";
import styled from "styled-components";
import {
  MainContainer,
  StepContainer,
  StepCount,
  StepLabel,
  StepStyle,
  StepWrapper,
  StepsLabelContainer,
} from "./style";

type StepperStepsProps = {
  label: string;
  step: number;
};
type StepperProps = {
  steps: StepperStepsProps[];
  activeStep: number | null;
};

/*
 * Stepper for multi-step forms
 */
const Stepper: React.FC<StepperProps> = (props) => {
  const { steps, activeStep } = props;
  const totalSteps = steps.length;
  const width = activeStep !== null && `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  return (
    <MainContainer>
      <StepContainer width={width}>
        {steps.map(({ step, label }) => (
          <StepWrapper key={step}>
            <StepStyle step={activeStep !== null && activeStep >= step ? "completed" : "incomplete"}>
              {activeStep !== null && activeStep > step ? (
                <FaCheck style={{ color: "#72bf6a" }} size={25} />
              ) : (
                <StepCount>{step}</StepCount>
              )}
            </StepStyle>
            <StepsLabelContainer>
              <StepLabel key={step}>{label}</StepLabel>
            </StepsLabelContainer>
          </StepWrapper>
        ))}
      </StepContainer>
    </MainContainer>
  );
};

export default Stepper;
