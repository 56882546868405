import { ReactElement, useMemo, useState } from "react";
import Stepper from "../../components/stepper/StepperForm";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { MultiStepFormContext } from "../../context/MuliStepFormContext";
import {
  TourCMSProps,
  getTourCmsParamsFromUrlQueryParams,
  redirectToProperlyStepUrlTourCMS,
  renderFormSteps,
  setActiveStepByUrlOfTourCMSIntegration,
  steps,
} from "./tour_cms/data/data";
import {
  BokunProps,
  getBokunParamsFromUrlQueryParams,
  redirectToProperlyStepUrlBokun,
  setActiveStepByUrlOfBokunIntegration,
} from "./bokun/data/data";
import { getUrlWithoutQueryParams } from "../../integrations/funcs";
import {
  FareHarborProps,
  getFareHarborParamsFromUrlQueryParams,
  redirectToProperlyStepUrlFareHarbor,
  setActiveStepByUrlOfFareHarborIntegration,
} from "./fare_harbor/data/data";
import {
  YieldPlanetProps,
  getYieldPlanetParamsFromUrlQueryParams,
  redirectToProperlyStepUrlYieldPlanet,
  setActiveStepByUrlOfYieldPlanetIntegration,
} from "./yield_planet/data/data";
import {
  HotresProps,
  getHotresParamsFromUrlQueryParams,
  redirectToProperlyStepUrlHotres,
  setActiveStepByUrlOfHotresIntegration,
} from "./hotres/data/data";

export type IntegrationDataProps = TourCMSProps | BokunProps | FareHarborProps | HotresProps | YieldPlanetProps | null;

const IntegrationFormPage = (): ReactElement => {
  const navigate = useNavigate();
  let location = useLocation();
  const [activeStep, setActiveStep] = useState<number | null>(1);
  const [data, setData] = useState<IntegrationDataProps>(() => {
    const searchParams = new URLSearchParams(location.search);
    // Get necessary params in tourCms integration process from Query Params
    if (searchParams.get("fromIntegrationId") === "tourCMS") {
      return getTourCmsParamsFromUrlQueryParams(searchParams);
    } else if (searchParams.get("fromIntegrationId") === "bokun") {
      return getBokunParamsFromUrlQueryParams(searchParams);
    } else if (searchParams.get("fromIntegrationId") === "fareHarbor") {
      return getFareHarborParamsFromUrlQueryParams(searchParams);
    } else if (searchParams.get("fromIntegrationId") === "hotres") {
      return getHotresParamsFromUrlQueryParams(searchParams);
    } else if (searchParams.get("fromIntegrationId") === "yieldPlanet") {
      return getYieldPlanetParamsFromUrlQueryParams(searchParams);
    } else {
      return {
        choose_integration_elements: {
          fromIntegrationId: null,
          toIntegrationId: null,
        },
        fill_integration_details: {
          accountId: null,
          channelId: null,
          partnerId: null,
          policy: null,
          fee: null,
        },
        integration_products: {
          integrationProductsComplete: null,
        },
      };
    }
  });

  useMemo(() => {
    const pathname = location.pathname;
    if (data?.choose_integration_elements.fromIntegrationId === "tourCMS") {
      setActiveStepByUrlOfTourCMSIntegration(pathname, setActiveStep);
    } else if (data?.choose_integration_elements.fromIntegrationId === "bokun") {
      setActiveStepByUrlOfBokunIntegration(pathname, setActiveStep);
    } else if (data?.choose_integration_elements.fromIntegrationId === "fareHarbor") {
      setActiveStepByUrlOfFareHarborIntegration(pathname, setActiveStep);
    } else if (data?.choose_integration_elements.fromIntegrationId === "hotres") {
      setActiveStepByUrlOfHotresIntegration(pathname, setActiveStep);
    } else if (data?.choose_integration_elements.fromIntegrationId === "yieldPlanet") {
      setActiveStepByUrlOfYieldPlanetIntegration(pathname, setActiveStep);
    }
  }, [location.pathname]);

  function changeStep(url: string) {
    const [formStepPathname, queryParams] = getUrlWithoutQueryParams(url);
    if (data?.choose_integration_elements.fromIntegrationId === "tourCMS") {
      redirectToProperlyStepUrlTourCMS(formStepPathname, queryParams, navigate);
    } else if (data?.choose_integration_elements.fromIntegrationId === "bokun") {
      redirectToProperlyStepUrlBokun(formStepPathname, queryParams, navigate);
    } else if (data?.choose_integration_elements.fromIntegrationId === "fareHarbor") {
      redirectToProperlyStepUrlFareHarbor(formStepPathname, queryParams, navigate);
    } else if (data?.choose_integration_elements.fromIntegrationId === "hotres") {
      redirectToProperlyStepUrlHotres(formStepPathname, queryParams, navigate);
    } else if (data?.choose_integration_elements.fromIntegrationId === "yieldPlanet") {
      redirectToProperlyStepUrlYieldPlanet(formStepPathname, queryParams, navigate);
    }
  }

  const contextData = data;
  const setContextData = setData;
  return (
    <>
      <Stepper steps={steps} activeStep={activeStep} />
      <FormContainer step={activeStep}>
        <MultiStepFormContext.Provider value={{ contextData, setContextData }}>
          {renderFormSteps(Number(activeStep), changeStep, String(data?.choose_integration_elements.fromIntegrationId))}
        </MultiStepFormContext.Provider>
      </FormContainer>
    </>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props: any) => (props.step === 3 ? "inherit" : "center")};
  width: 90%;
  margin: auto;
  margin: 40px auto;
`;

export default IntegrationFormPage;
