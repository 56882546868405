import styled from "styled-components";

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
`;

export const Select = styled.select`
  width: 100%;
  height: 40px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #f5f5f5;
  appearance: none;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #333;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  }
`;

export const Arrow = styled.div`
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  pointer-events: none;
  margin-top: 3px;
  &::before {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #333 transparent transparent transparent;
  }

  &::before {
    top: -6px;
  }

  &::after {
    top: 1px;
  }
`;

export const StyledOption = styled.option`
  background-color: white;
  color: #555;
  font-size: 16px;
  border-bottom: 1px solid #ccc;

  &:hover {
    background-color: darkviolet;
    color: #333;
  }

  &:focus {
    outline: none;
    background-color: darkviolet;
    color: #333;
  }
`;
