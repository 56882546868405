import React, { ReactElement } from "react";
import styled from "styled-components";

const HomePage = (): ReactElement => {
  return (
    <HomePageWrapper>
      <HomeTitle>Witamy w Connectly!</HomeTitle>
      <HomeShortDescription>
        W naszym serwisie możesz zintegrować się między zewnętrznymi serwisami turystycznymi..
      </HomeShortDescription>
    </HomePageWrapper>
  );
};

const HomePageWrapper = styled.div`
  text-align: center;
`;

const HomeTitle = styled.div`
  font-size: 36px;
  margin: 20px 0;
`;

const HomeShortDescription = styled.div`
  font-size: 22px;
  color: #55bf6a;
`;

export default HomePage;
