import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import { HeaderButtonProps } from "../header/Header";
import { Dropdown, DropdownContent, DropdownItem } from "./style";

const DropdownButton: React.FC<any> = ({ buttons }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [userInfo, setUserInfo] = useState<{ email: string; token: string } | null>(null);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("token");
    setUserInfo(null);
    window.location.reload();
  };

  useEffect(() => {
    if (isOpen) {
      const userEmail = sessionStorage.getItem("email");
      const userToken = sessionStorage.getItem("token");
      if (userEmail && userToken) {
        setUserInfo({
          email: userEmail,
          token: userToken,
        });
      }
    }
  }, [isOpen]);

  return (
    <Dropdown>
      <Button onClick={handleButtonClick}>Menu</Button>
      {isOpen && (
        <DropdownContent>
          {buttons.map((button: HeaderButtonProps, index: number) => {
            return (
              <DropdownItem to={button.endpoint} key={button.endpoint}>
                <Button
                  key={index}
                  onClick={() => {
                    handleButtonClick();
                    navigate(button.endpoint);
                  }}
                >
                  {button.label}
                </Button>
              </DropdownItem>
            );
          })}

          <DropdownItem>
            <Button
              onClick={() => {
                if (userInfo) {
                  handleLogout();
                } else {
                  window.location.href = "/login";
                }
                handleButtonClick();
              }}
              className={userInfo ? "logout" : "login"}
            >
              {userInfo ? "Wyloguj się" : "Zaloguj się"}
            </Button>
          </DropdownItem>
        </DropdownContent>
      )}
    </Dropdown>
  );
};

export default DropdownButton;
