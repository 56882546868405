import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import IntegrationProductTile, {
  ProductProps,
} from "../../../components/integration_product_tile/IntegrationProductTile";
import ApiService from "../../../api/ApiService";
import { toast } from "react-toastify";
import { toastifyParameters } from "../../style/main";
import Button from "../../../components/buttons/Button";
import { ProcessingSpinner, Spinner } from "../../integration_form_page/tour_cms/step_2/style";
import Pagination from "../../../components/pagination/Pagination";
import {
  ButtonGroup,
  FeeForm,
  InputFee,
  IntegrationProducts,
  IntegrationProductsPanel,
  LabelBold,
  PaginationPanel,
  Value,
  View,
} from "../../integration_form_page/style/style";
import { IntegrationTile } from "../../../components/integration_image_tile/style";

const IntegrationProductsFareHarborPage = () => {
  const navigate = useNavigate();
  const { companyId, companySourceId, connectlySourceId, fee } = useParams();
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [products, setProducts] = useState<ProductProps[]>();
  const [productSize, setProductSize] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const integrationProductsRef = useRef<HTMLDivElement>(null);
  const [newFee, setNewFee] = useState<number>(Number(fee));
  const [changeFeeStatus, setChangeFeeStatus] = useState<boolean>(false);

  const getProducts = () => {
    if (products && products.length === 0) setLoading(true);
    let data = {
      companyId: Number(companyId),
      sourceId: Number(connectlySourceId),
      channelId: null,
      page: page,
      size: size,
    };
    const apiService = new ApiService();
    apiService
      .getProducts(data)
      .then((response) => {
        if (products && products.length === 0) {
          toast.success(`Pomyślnie pobrano produkty ! (Strona: ${page} ; Ilość na stronie: ${size})`);
        }
        setProducts(response.data.list);
        setProductSize(Number(response.data.rowCount));
      })
      .catch((err) => {
        toast.error(`Pobranie produktów nie powiodło się !`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (integrationProductsRef.current) {
      integrationProductsRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [page]);

  useEffect(() => {
    if (companyId !== null) getProducts();
  }, [page, size, companyId]);

  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const handlePageSizeChange = (size: number) => {
    setSize(size);
    setPage(1);
  };

  const updateProducts = () => {
    getProducts();
  };

  const addAllTours = () => {
    setIsProcessing(true);
    const requests: any[] = [];
    let allActivated = true;
    products &&
      products?.forEach((product) => {
        if (!product?.exist) {
          let addTourData = {
            sourceId: product?.company?.id,
            companyId: Number(companyId),
            sourceTourId: Number(product?.idInSource),
            slug: Number(product?.slug),
          };
          const apiService = new ApiService();
          const request = apiService
            .addTour(addTourData)
            .then(() => {})
            .catch(() => {
              allActivated = false;
            });
          requests.push(request);
        }
      });
    Promise.all(requests)
      .then(() => {
        updateProducts();
        setIsProcessing(false);
        if (allActivated) {
          toast.success(`Wszystkie produkty zostały dodane pomyślnie !`, toastifyParameters);
        } else {
          toast.error(`Nie udało się dodać wszystkich produktów (z Fare Harbor) do Tripsomni`, toastifyParameters);
        }
      })
      .catch(() => {
        updateProducts();
        setIsProcessing(false);
        toast.error(`Nie udało się dodać wszystkich produktów (z Fare Harbor) do Tripsomni`, toastifyParameters);
      });
  };

  const actualizeAllTours = () => {
    setIsProcessing(true);
    const requests: any[] = [];
    let allActivated = true;
    products &&
      products?.forEach((product) => {
        if (product?.exist) {
          let actualizeProductData = {
            id: Number(product?.idInTripsomnia),
          };
          const apiService = new ApiService();
          const request = apiService
            .actualizeProduct(actualizeProductData)
            .then(() => {})
            .catch(() => {
              allActivated = false;
            });
          requests.push(request);
        }
      });
    Promise.all(requests)
      .then(() => {
        updateProducts();
        if (allActivated) {
          toast.success(`Zaktualizowano wszystkie produkty !`, toastifyParameters);
        } else {
          toast.error(`Nie udało się zaktualizować wszystkich produktów`, toastifyParameters);
        }
      })
      .catch(() => {
        toast.error(`Nie udało się zaktualizować wszystkich produktów`, toastifyParameters);
      });
  };

  const inactiveAllTours = () => {
    setIsProcessing(true);
    const requests: any[] = [];
    let allActivated = true;
    products &&
      products?.forEach((product) => {
        if (product?.exist && product?.isActive) {
          let activeProductData = {
            id: Number(product?.idInTripsomnia),
            status: false,
          };
          const apiService = new ApiService();
          const request = apiService
            .activeProduct(activeProductData)
            .then(() => {})
            .catch(() => {
              allActivated = false;
            });
          requests.push(request);
        }
      });
    Promise.all(requests)
      .then(() => {
        updateProducts();
        setIsProcessing(false);
        if (allActivated) {
          toast.success(`Wszystkie produkty zostały zdezaktywowane`, toastifyParameters);
        } else {
          toast.error(`Nie udało się dezaktywować wszystkich produktów`, toastifyParameters);
        }
      })
      .catch(() => {
        updateProducts();
        setIsProcessing(false);
        toast.error(`Nie udało się dezaktywować wszystkich produktów`, toastifyParameters);
      });
  };

  const activeAllTours = () => {
    setIsProcessing(true);
    const requests: any[] = [];
    let allActivated = true;
    products &&
      products?.forEach((product) => {
        if (product?.exist && !product?.isActive) {
          let activeProductData = {
            id: Number(product?.idInTripsomnia),
            status: true,
          };
          const apiService = new ApiService();
          const request = apiService
            .activeProduct(activeProductData)
            .then(() => {})
            .catch(() => {
              allActivated = false;
            });
          requests.push(request);
        }
      });
    Promise.all(requests)
      .then(() => {
        updateProducts();
        setIsProcessing(false);
        if (allActivated) {
          toast.success(`Wszystkie produkty zostały aktywowane !`, toastifyParameters);
        } else {
          toast.error(`Nie udało się aktywować wszystkich produktów`, toastifyParameters);
        }
      })
      .catch(() => {
        updateProducts();
        setIsProcessing(false);
        toast.error(`Nie udało się aktywować wszystkich produktów`, toastifyParameters);
      });
  };

  const changeFee = () => {
    if (changeFeeStatus) {
      let updateCommissionData = {
        id: Number(companySourceId),
        percent: Number(newFee),
      };
      const apiService = new ApiService();
      apiService
        .updateCommission(updateCommissionData)
        .then((response) => {
          setChangeFeeStatus(!changeFeeStatus);
          toast.success(`Zmiana prowizji na ${updateCommissionData.percent}%`, toastifyParameters);
        })
        .catch((err) => {
          toast.error(`Nie udało się zmienić prowizji`, toastifyParameters);
        });
    }
    setChangeFeeStatus(!changeFeeStatus);
  };

  const handleFeeChange = (e: any) => {
    setNewFee(e.target.value);
  };

  return (
    <View isProcessing={isProcessing}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px",
          fontSize: "15px",
          fontWeight: "bold",
          color: "#707070",
        }}
      >
        <div>Company ID: {companyId}</div>
        <div>Company Source ID: {companySourceId}</div>
        <div>Connectly Source ID: {connectlySourceId}</div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "10px",
          margin: "auto",
        }}
      >
        <Button onClick={changeFee}>{changeFeeStatus ? "Zmień" : "Edytuj"}</Button>
        {changeFeeStatus ? (
          <FeeForm isChangeFee={changeFeeStatus}>
            <InputFee type="text" id="channel-id" name="channelId" value={newFee} onChange={handleFeeChange} />
          </FeeForm>
        ) : (
          <LabelBold>
            Prowizja: <Value>{newFee}%</Value>
          </LabelBold>
        )}
      </div>
      <ButtonGroup>
        <Button
          onClick={() => {
            navigate("/integrations_list");
          }}
        >
          WRÓĆ DO LISTY
        </Button>
        <IntegrationTile
          src={"https://seeklogo.com/images/F/fareharbor-logo-1A7BAC1D0C-seeklogo.com.png"}
          width={50}
          height={30}
          active={true}
          visible={true}
        />
      </ButtonGroup>
      <IntegrationProductsPanel>
        <Button onClick={addAllTours}>Dodaj wszystkie</Button>
        {/* <Button onClick={actualizeAllTours}>Aktualizuj wszystkie</Button> */}
        <Button onClick={activeAllTours}>Wyłącz wszystkie</Button>
        <Button onClick={inactiveAllTours}>Włącz wszystkie</Button>
      </IntegrationProductsPanel>
      <IntegrationProducts ref={integrationProductsRef}>
        {!loading ? (
          products?.map((product) => {
            return (
              <IntegrationProductTile
                companyId={Number(companyId)}
                key={product.idInSource}
                actualized={false}
                {...product}
                updateProducts={updateProducts}
              />
            );
          })
        ) : (
          <div
            style={{
              fontSize: "20px",
              color: "#72bf6a",
              display: "flex",
              margin: "5vh auto",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "700",
            }}
          >
            Ładowanie produktów... <Spinner size="20px" color="#72bf6a" />
          </div>
        )}
        {isProcessing && <ProcessingSpinner isProcessing={isProcessing} />}
      </IntegrationProducts>
      <PaginationPanel>
        <Pagination
          currentPage={page}
          totalPages={!isNaN(Number(productSize)) ? Math.ceil(Number(productSize) / size) : 1}
          onPageChange={handlePageChange}
          buttonStyle={{ border: "1px solid gray" }}
          activeButtonStyle={{ backgroundColor: "#72bf6a" }}
          getRowsPerPageCallback={handlePageSizeChange}
          rowsPerPageOptions={[1, 2, 5, 10, 20, 50]}
          defaultRowsPerPage={10}
        />
      </PaginationPanel>
    </View>
  );
};

export default IntegrationProductsFareHarborPage;
