import styled from "styled-components";

export const Chevron = styled.div`
  position: relative;
  display: flex;
  background-color: #e0e0e0;
  padding: 5px 3px;
  margin: 5px 2px;
  border-radius: 3px;
`;

export const CardTile = styled.div`
  display: flex;
  padding: 8px 0 8px 8px;
  width: 100%;
  margin: 5px 2px;
  height: 200px;
  border-radius: 8px 0 0 8px;
  background: linear-gradient(109.6deg, rgba(254, 253, 205, 1) 11.2%, rgba(163, 230, 255, 1) 91.1%);
  @media (max-width: 768px) {
    height: 140px;
  }
`;

export const CardImage = styled.img`
  width: 35%;
  height: 95%;
  object-fit: cover;
  border: 1px solid gray;
  border-radius: 8px;
  margin: 5px;
  @media (max-width: 768px) {
    width: 25%;
    height: 40%;
  }
`;

export const CardContent = styled.div`
  display: block;
  width: 55%;
  padding: 5px;
  height: 130px;
  @media (max-width: 768px) {
    width: 70%;
  }
`;

export const AdditionalInfo = styled.div`
  position: relative;
  width: 15%;

  @media (max-width: 768px) {
    width: 20%;
  }
`;

export const BottomRight = styled.div`
  bottom: 0;
  right: 5px;
  position: absolute;
  font-size: 11px;
  @media (max-width: 768px) {
    font-size: 9px;
  }
`;

export const RightTop = styled.div`
  top: 5px;
  right: 5px;
  font-size: 12px;
  position: absolute;
  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

export const Title = styled.p`
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  height: 25px;
  @media (max-width: 768px) {
    font-size: 12px;
    height: 10px;
  }
`;

export const Description = styled.p`
  margin: 2px;
  font-size: 14px;
  overflow: auto;
  word-wrap: break-word;
  width: 100%;
  height: 125px;
  padding: 2px;
  @media (max-width: 768px) {
    font-size: 10px;
    height: 90px;
  }
`;
