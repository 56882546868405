import { getUrlWithoutQueryParams } from "../../../../integrations/funcs";
import IntegrationDetailsBokunStep from "../../bokun/step_2/IntegrationDetailsBokunStep";
import IntegrationProductsBokunStep from "../../bokun/step_3/IntegrationProductsBokunStep";
import IntegrationDetailsFareHarborStep from "../../fare_harbor/step_2/IntegrationDetailsFareHarborStep";
import IntegrationProductsFareHarborStep from "../../fare_harbor/step_3/IntegrationProductsFareHarborStep";
import IntegrationDetailsHotresStep from "../../hotres/step_2/IntegrationDetailsHotresStep";
import IntegrationProductsHotresStep from "../../hotres/step_3/IntegrationProductsHotresStep";
import ChooseIntegrationElementsStep from "../../step_1/ChooseIntegrationElementsStep";
import IntegrationDetailsYieldPlanetStep from "../../yield_planet/step_2/IntegrationDetailsYieldPlanetStep";
import IntegrationProductsYieldPlanetStep from "../../yield_planet/step_3/IntegrationProductsYieldPlanetStep";
import IntegrationDetailsStep from "../step_2/IntegrationDetailsStep";
import IntegrationProductsStep from "../step_3/IntegrationProductsStep";

export const steps = [
  {
    label: "Integration Connection",
    step: 1,
  },
  {
    label: "Integration Details",
    step: 2,
  },
  {
    label: "Integration Products",
    step: 3,
  },
];

export const renderFormSteps = (activeStep: number, changeStep: (url: string) => void, fromIntegrationId: string) => {
  if (fromIntegrationId === "tourCMS") {
    if (activeStep === 1) return <ChooseIntegrationElementsStep handleChangeFormStep={changeStep} />;
    if (activeStep === 2) return <IntegrationDetailsStep handleChangeFormStep={changeStep} />;
    if (activeStep === 3) return <IntegrationProductsStep handleChangeFormStep={changeStep} />;
  } else if (fromIntegrationId === "bokun") {
    if (activeStep === 1) return <ChooseIntegrationElementsStep handleChangeFormStep={changeStep} />;
    if (activeStep === 2) return <IntegrationDetailsBokunStep handleChangeFormStep={changeStep} />;
    if (activeStep === 3) return <IntegrationProductsBokunStep handleChangeFormStep={changeStep} />;
  } else if (fromIntegrationId === "fareHarbor") {
    if (activeStep === 1) return <ChooseIntegrationElementsStep handleChangeFormStep={changeStep} />;
    if (activeStep === 2) return <IntegrationDetailsFareHarborStep handleChangeFormStep={changeStep} />;
    if (activeStep === 3) return <IntegrationProductsFareHarborStep handleChangeFormStep={changeStep} />;
  } else if (fromIntegrationId === "hotres") {
    if (activeStep === 1) return <ChooseIntegrationElementsStep handleChangeFormStep={changeStep} />;
    if (activeStep === 2) return <IntegrationDetailsHotresStep handleChangeFormStep={changeStep} />;
    if (activeStep === 3) return <IntegrationProductsHotresStep handleChangeFormStep={changeStep} />;
  } else if (fromIntegrationId === "yieldPlanet") {
    if (activeStep === 1) return <ChooseIntegrationElementsStep handleChangeFormStep={changeStep} />;
    if (activeStep === 2) return <IntegrationDetailsYieldPlanetStep handleChangeFormStep={changeStep} />;
    if (activeStep === 3) return <IntegrationProductsYieldPlanetStep handleChangeFormStep={changeStep} />;
  } else {
    if (activeStep === 1) return <ChooseIntegrationElementsStep handleChangeFormStep={changeStep} />;
    if (activeStep === 2) return <IntegrationDetailsStep handleChangeFormStep={changeStep} />;
    if (activeStep === 3) return <IntegrationProductsStep handleChangeFormStep={changeStep} />;
  }
};

export interface TourCMSProps {
  choose_integration_elements: {
    fromIntegrationId: string | null;
    toIntegrationId: string | null;
  };
  fill_integration_details: {
    accountId: string | null;
    channelId: string | null;
    partnerId: string | null;
    policy: boolean | null;
    fee: number | null;
  };
  integration_products: {
    integrationProductsComplete: any;
  };
}

export const setActiveStepByUrlOfTourCMSIntegration = (pathname: string, setActiveStep: (step: number) => void) => {
  let stepPath = getUrlWithoutQueryParams(pathname)[0];
  if (stepPath === "/integrationForm/chooseIntegrationElements") {
    setActiveStep(1);
  } else if (stepPath === "/integrationForm/tourCMS/fillIntegrationDetails") {
    setActiveStep(2);
  } else if (stepPath === "/integrationForm/tourCMS/integrationProducts") {
    setActiveStep(3);
  }
};

export const getTourCmsParamsFromUrlQueryParams = (searchParams: any) => {
  const fromIntegrationId = searchParams.get("fromIntegrationId") || null;
  const toIntegrationId = searchParams.get("toIntegrationId") || null;
  const accountId = searchParams.get("accountId") || null;
  const channelId = searchParams.get("channelId") || null;
  const partnerId = searchParams.get("partnerId") || null;
  const policy = Boolean(searchParams.get("policy"));
  const fee = Number(searchParams.get("fee"));
  const integrationProductsComplete = searchParams.get("integrationProductsComplete");
  return {
    choose_integration_elements: {
      fromIntegrationId,
      toIntegrationId,
    },
    fill_integration_details: {
      accountId,
      channelId,
      partnerId,
      policy,
      fee,
    },
    integration_products: {
      integrationProductsComplete,
    },
  };
};

export const redirectToProperlyStepUrlTourCMS = (formStepPathname: string, queryParams: string, navigate: any) => {
  const queryParameters = new URLSearchParams(queryParams);
  if (formStepPathname === "/integrationForm/chooseIntegrationElements") {
    navigate(decodeURIComponent(`/integrationForm/chooseIntegrationElements?${queryParameters}`));
  } else if (formStepPathname === "/integrationForm/tourCMS/fillIntegrationDetails") {
    navigate(decodeURIComponent(`/integrationForm/tourCMS/fillIntegrationDetails?${queryParameters}`));
  } else if (formStepPathname === `/integrationForm/tourCMS/integrationProducts`) {
    navigate(decodeURIComponent(`/integrationForm/tourCMS/integrationProducts?${queryParameters}`));
  }
};

export const initialTourCMSData: TourCMSProps | undefined = {
  choose_integration_elements: {
    fromIntegrationId: null,
    toIntegrationId: null,
  },
  fill_integration_details: {
    accountId: null,
    channelId: null,
    partnerId: null,
    policy: false,
    fee: 0,
  },
  integration_products: {
    integrationProductsComplete: false,
  },
};
