import { getUrlWithoutQueryParams } from "../../../../integrations/funcs";

export const yieldPlanetSteps = [
  {
    label: "Integration Connection",
    step: 1,
  },
  {
    label: "Integration Details",
    step: 2,
  },
  {
    label: "Integration Products",
    step: 3,
  },
];

export const renderFormSteps = (activeStep: number, changeStep: (url: string) => void) => {
  //   if(activeStep === 1) return <ChooseIntegrationElementsStep handleChangeFormStep={changeStep} />
  //   if(activeStep === 2) return <IntegrationDetailsStep handleChangeFormStep={changeStep} />
  //   if(activeStep === 3) return <IntegrationProductsStep handleChangeFormStep={changeStep} />
};

export interface YieldPlanetProps {
  choose_integration_elements: {
    fromIntegrationId: string | null;
    toIntegrationId: string | null;
  };
  fill_integration_details: {
    companyId: string | null;
    login: string | null;
    password: string | null;
    policy: boolean | null;
  };
  integration_products: {
    integrationProductsComplete: any;
  };
}

export const setActiveStepByUrlOfYieldPlanetIntegration = (pathname: string, setActiveStep: (step: number) => void) => {
  let stepPath = getUrlWithoutQueryParams(pathname)[0];
  if (stepPath === "/integrationForm/chooseIntegrationElements") {
    setActiveStep(1);
  } else if (stepPath === "/integrationForm/yieldPlanet/fillIntegrationDetails") {
    setActiveStep(2);
  } else if (stepPath === "/integrationForm/yieldPlanet/integrationProducts") {
    setActiveStep(3);
  }
};

export const getYieldPlanetParamsFromUrlQueryParams = (searchParams: any) => {
  const fromIntegrationId = searchParams.get("fromIntegrationId") || null;
  const toIntegrationId = searchParams.get("toIntegrationId") || null;
  const companyId = searchParams.get("companyId") || null;
  const login = searchParams.get("login") || null;
  const password = searchParams.get("password") || null;
  const policy = Boolean(searchParams.get("policy"));
  const integrationProductsComplete = searchParams.get("integrationProductsComplete");
  return {
    choose_integration_elements: {
      fromIntegrationId,
      toIntegrationId,
    },
    fill_integration_details: {
      companyId,
      login,
      password,
      policy,
    },
    integration_products: {
      integrationProductsComplete,
    },
  };
};

export const redirectToProperlyStepUrlYieldPlanet = (formStepPathname: string, queryParams: string, navigate: any) => {
  const queryParameters = new URLSearchParams(queryParams);
  if (formStepPathname === "/integrationForm/chooseIntegrationElements") {
    navigate(decodeURIComponent(`/integrationForm/chooseIntegrationElements?${queryParameters}`));
  } else if (formStepPathname === "/integrationForm/yieldPlanet/fillIntegrationDetails") {
    navigate(decodeURIComponent(`/integrationForm/yieldPlanet/fillIntegrationDetails?${queryParameters}`));
  } else if (formStepPathname === `/integrationForm/yieldPlanet/integrationProducts`) {
    navigate(decodeURIComponent(`/integrationForm/yieldPlanet/integrationProducts?${queryParameters}`));
  }
};

export const initialYieldPlanetData: YieldPlanetProps | undefined = {
  choose_integration_elements: {
    fromIntegrationId: null,
    toIntegrationId: null,
  },
  fill_integration_details: {
    companyId: null,
    login: null,
    password: null,
    policy: false,
  },
  integration_products: {
    integrationProductsComplete: false,
  },
};
