import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import styled from "styled-components";
import TripsomniaApiService from "../../api/TripsomniaApiService";
import { keyframes } from "styled-components";
import { useQueryParams } from "../../utils/helpers";

type FormValuesType = {
  email: string;
  password: string;
};
const initialFormState: FormValuesType = {
  email: "",
  password: "",
};

const LoginPage = () => {
  const [formValues, setFormValues] = useState<FormValuesType>(initialFormState);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const queryParams = useQueryParams();
  const emailParam = queryParams.email;
  const tokenParam = queryParams.token;

  useEffect(() => {
    if (emailParam && tokenParam) {
      setIsLoading(true);
      sessionStorage.setItem("email", emailParam);
      sessionStorage.setItem("token", tokenParam);
      setIsLoading(false);
      window.location.href = "/";
    }
  }, [emailParam, tokenParam]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      const { email, password } = formValues;
      const tripsomniaApiService = new TripsomniaApiService();
      const response = await tripsomniaApiService.loginUser({ email, password });

      setIsLoading(false);

      if (response.data.errors) {
        setError(response.data.errors[0].detail);
      } else {
        const userEmail = response.data.data.attributes.email;
        const userToken = response.data.meta.auth_token;
        sessionStorage.setItem("email", userEmail);
        sessionStorage.setItem("token", userToken);
        window.location.href = "/";
      }
    } catch (error: any) {
      setIsLoading(false);
      setError(error.response.data.errors[0].detail);
    }
  };

  return (
    <StyledLoginPageWrapper>
      <h1>Zaloguj się</h1>

      <StyledForm
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          handleLogin();
        }}
      >
        <StyledFormField>
          <label htmlFor="email">Adres email *</label>
          <input
            type="email"
            name="email"
            id="email"
            required
            placeholder="Wpisz swój emial"
            value={formValues.email}
            onChange={handleInputChange}
          />
        </StyledFormField>

        <StyledFormField>
          <label htmlFor="password">Hasło *</label>
          <input
            type="password"
            name="password"
            id="password"
            required
            placeholder="Wpisz swoje hasło"
            value={formValues.password}
            onChange={handleInputChange}
          />
        </StyledFormField>

        {error && <p className="error">{error}</p>}

        <button type="submit">{isLoading ? <Spinner /> : "Zaloguj się"}</button>
      </StyledForm>
    </StyledLoginPageWrapper>
  );
};

const StyledLoginPageWrapper = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: 24px;
    margin-bottom: 25px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 450px;

  .error {
    margin: 0;
    color: red;
  }

  button {
    padding: 10px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    background-color: #46923c;
    color: white;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledFormField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 16px;

  input {
    font-size: 16px;
    padding: 12px 16px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;

    &::placeholder {
      font-weight: 100;
      color: #c1c1c1;
    }
  }
`;

const spinAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: ${spinAnimation} 1s linear infinite;
`;

export default LoginPage;
