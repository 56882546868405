import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import { IntegrationItemProps } from "../../../data/data";
import ApiService from "../../../api/ApiService";
import ToggleButton from "../../buttons/ToggleButton";
import { useNavigate } from "react-router-dom";
import { toastifyParameters } from "../../../pages/style/main";
import { toast } from "react-toastify";

interface IntegrationProductsUrlProps {
  connectlySourceId: number | null | undefined;
  accountId: number | null | undefined;
  channelId: number | null | undefined;
  companyId: number | null | undefined;
  companySourceId: number | null | undefined;
  commission: number | null | undefined;
}

const getIntegrationProductsPageUrl = ({
  connectlySourceId,
  accountId,
  channelId,
  companyId,
  companySourceId,
  commission,
}: IntegrationProductsUrlProps): string | null => {
  switch (Number(connectlySourceId)) {
    case 1:
      return `/integrationProducts/yieldPlanet/${companyId}/${companySourceId}`;
    case 2:
      return `/integrationProducts/tourCMS/${channelId}/${accountId}/${companyId}/${companySourceId}/${connectlySourceId}/${commission}`;
    case 3:
      return `/integrationProducts/bokun/${companyId}/${companySourceId}/${connectlySourceId}/${commission}`;
    case 4:
      return `/integrationProducts/hotres/${companyId}/${companySourceId}`;
    case 5:
      return `/integrationProducts/fareHarbor/${companyId}/${companySourceId}/${connectlySourceId}/${commission}`;
    default:
      return null;
  }
};

/*
 * Integration table row for each integration in Connectly
 */
const IntegrationTableRow: React.FC<IntegrationItemProps> = (props: IntegrationItemProps): ReactElement => {
  const {
    index,
    companyId,
    companyName,
    connectlySources,
    isActive,
    destinationSystem,
    accountId,
    channelId,
    companySourceId,
    commission,
    offers,
  } = props;
  const navigate = useNavigate();
  const { id, name, status } = connectlySources;
  const [activeIntegration, setActiveIntegration] = useState(isActive);

  const toggleIntegrationActivation = (isChecked: boolean) => {
    let updateActiveSourceData = {
      id: companySourceId,
      status: isChecked,
    };
    const apiService = new ApiService();
    apiService
      .updateActiveSource(updateActiveSourceData)
      .then((response) => {
        setActiveIntegration(isChecked);
        toast.success(
          `Integracja o id: ${companySourceId} została ${isChecked ? "aktywowana" : "dezaktywowana"}`,
          toastifyParameters
        );
      })
      .catch((err) => {
        toast.error(
          `Nie udało się ${isChecked ? "aktywować" : "dezaktywować"} integracji o id: ${companySourceId}`,
          toastifyParameters
        );
      });
  };

  const handleToggle = (isChecked: boolean) => {
    toggleIntegrationActivation(isChecked);
  };

  return (
    <tr style={{ margin: "auto", textAlign: "center" }}>
      <IntegrationCell>{index}</IntegrationCell>
      <IntegrationCell>{companyId}</IntegrationCell>
      <IntegrationCell>Opis</IntegrationCell>
      <IntegrationCell
        pointer={true}
        onClick={() => {
          const connectlySourceId = connectlySources.id;
          const url = getIntegrationProductsPageUrl({
            connectlySourceId,
            accountId,
            channelId,
            companyId,
            companySourceId,
            commission,
          });
          if (url !== null) {
            navigate(url);
          }
        }}
      >{`${name} <--> ${destinationSystem}`}</IntegrationCell>
      <IntegrationCell>{`${companyName} (accountId:${accountId} ; channelId:${channelId})`}</IntegrationCell>
      <IntegrationCell>
        <ToggleButton onToggle={handleToggle} defaultChecked={activeIntegration} />
      </IntegrationCell>
      <IntegrationCell>{offers}</IntegrationCell>
    </tr>
  );
};

const IntegrationCell = styled.td`
  padding: 15px;
  cursor: ${({ pointer }: any) => (pointer ? "pointer" : "default")};
  font-weight: ${({ pointer }: any) => (pointer ? "700" : "500")};
  font-size: ${({ pointer }: any) => (pointer ? "20px" : "17px")};
  color: ${({ pointer }: any) => (pointer ? "green" : "black")};
`;

export default IntegrationTableRow;
