import { convertCurrencyNameToSign } from "../../utils/helpers";
import IntegrationProductInfoActions from "./actions/IntegrationProductInfoActions";
import IntegrationProductInfoSummary from "./summary/IntegrationProductInfoSummary";
import { useState } from "react";
import { BsBookmarkCheckFill } from "react-icons/bs";
import { TbPlugConnectedX } from "react-icons/tb";
import {
  AdditionalInfo,
  BottomRight,
  CardContent,
  CardImage,
  CardTile,
  Chevron,
  Description,
  RightTop,
  Title,
} from "./style";
export interface IntegrationProps {
  id: number;
  name: string;
}

export interface ProductProps {
  channelId: string;
  channelName: string;
  company: IntegrationProps;
  idInSource: number;
  idInTripsomnia: number | null;
  title: string;
  description: string;
  exist: boolean;
  isActive: boolean | null;
  price: number;
  saleCurrency: string;
  location: string;
  thumbnailImage?: string;
  image?: string;
  duration: number;
  fee?: number;
  actualized?: boolean | false;
  slug: number | null;
  companyId?: number;
  changeActualizedState?: (state: boolean) => void;
  updateProducts?: () => void;
  noConnIntegration?: boolean;
}

/*
 * Integration Product tile (in listing)
 */
const IntegrationProductTile = (props: ProductProps) => {
  const {
    channelId,
    channelName,
    company,
    idInSource,
    idInTripsomnia,
    title,
    description,
    exist,
    isActive,
    price,
    saleCurrency,
    location,
    thumbnailImage,
    image,
    duration,
    fee,
    companyId,
    updateProducts,
    noConnIntegration = false,
  } = props;
  const [actualized, setActualized] = useState<boolean>(false);

  return (
    <div style={{ display: "flex" }}>
      <CardTile>
        <CardImage src={thumbnailImage ? thumbnailImage : "https://automobilio.info/auto/no-img.png"} />
        <CardContent>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </CardContent>
        <AdditionalInfo>
          <RightTop>
            From:{" "}
            <strong>
              {price}
              {convertCurrencyNameToSign(saleCurrency)}
            </strong>
          </RightTop>
          <BottomRight>{location}</BottomRight>
        </AdditionalInfo>
      </CardTile>
      {actualized ? <IntegrationProductInfoSummary {...props} /> : <IntegrationProductInfoActions {...props} />}
      <Chevron>
        <TbPlugConnectedX
          style={{ bottom: 5, position: "absolute" }}
          size={20}
          color={!actualized ? "green" : "gray"}
          onClick={() => setActualized((prevState) => !prevState)}
        />
        <BsBookmarkCheckFill
          style={{ top: 5 }}
          size={20}
          color={actualized ? "green" : "gray"}
          onClick={() => setActualized((prevState) => !prevState)}
        />
      </Chevron>
    </div>
  );
};

export default IntegrationProductTile;
